import svgIcons from 'e-icon-picker/lib/getSvg';
import { PermissionScopeEnum, ResourceStatusEnum } from '@/resource/enum';
import { PermissionResource } from '@/resource/model';
import { CascaderOption } from 'element-ui/types/cascader-panel';
import { Form } from 'element-ui/types/element-ui';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { EIconPicker } from 'e-icon-picker';
import { Message } from 'element-ui';
import { platformPermissionService } from '@/api';
import { messageError, translation } from '@/utils';
import { DialogMixin } from '@/mixins/dialog';
import { mixins } from 'vue-class-component';

@Component({
  components: { EIconPicker }
})
export default class AddPlatformPermission extends mixins(DialogMixin) {
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return null;
    }
  })
  public resourceItem!: PermissionResource | null;

  @Prop({ required: false, type: String, default: '0' }) public parentId!: string;

  public resourceForm: {
    parentId: string;
    scope: PermissionScopeEnum;
    icon: string;
    name: string;
    code: string;
    url: string;
    status: ResourceStatusEnum;
    sort: number;
  } = {
    parentId: '',
    scope: PermissionScopeEnum.menu,
    icon: '',
    name: '',
    code: '',
    url: '',
    status: ResourceStatusEnum.using,
    sort: 1
  };

  public resourceFormRules = {
    name: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('platformPermission.resourceNameEmpty')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          if (value.includes(' ')) {
            callback(new Error(translation('platformPermission.resourceNameSpace')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          platformPermissionService
            .checkResourcesName(value, this.resourceItem?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('requestError.rsourceNameRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    code: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('platformPermission.resourceCodeEmpty')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          platformPermissionService
            .checkResourcesCode(value, this.resourceItem?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('requestError.rsourceCodeRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    url: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('platformPermission.resourceUrlEmpty')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    sort: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('platformPermission.resourceSortEmpty')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ]
  };

  public treeOption: Array<CascaderOption> = [];

  public iconOptions = {
    FontAwesome: false,
    ElementUI: false,
    eIcon: false, // 自带的图标，来自阿里妈妈
    eIconSymbol: false, // 是否开启彩色图标
    addIconList: [],
    removeIconList: []
  };

  private operationType: 'add' | 'edit' = 'add';

  public mounted(): void {
    this.iconOptions.addIconList = svgIcons;
  }

  public dialogOpen(): void {
    this.resourceForm = {
      parentId: '',
      scope: PermissionScopeEnum.menu,
      icon: '',
      name: '',
      code: '',
      url: '',
      status: ResourceStatusEnum.using,
      sort: 1
    };
    if (this.parentId) {
      this.resourceForm.parentId = this.parentId;
    }
    this.getPermissionMenuResource().then(() => {
      this.treeOption = this.filterSelfResource(this.treeOption);
    });
    if (!this.resourceItem) {
      this.operationType = 'add';
      this.title = 'platformPermission.addResource';
      return;
    }
    this.operationType = 'edit';
    this.title = 'platformPermission.editResource';
    this.$nextTick(() => {
      Object.assign(this.resourceForm, this.resourceItem);
    });
  }

  /**
   * 	Dialog 关闭动画结束时的回调
   */
  public dialogClosed(): void {
    this.$emit('dialog-closed');
  }

  public onSubmit(): void {
    (this.$refs.resourceForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        if (this.operationType === 'add') {
          await platformPermissionService.post({ ...this.resourceForm } as any);
          Message.success(translation('operationRes.addSuccess'));
          this.$emit('add-success');
          this.closeDialog();
          return;
        }
        await platformPermissionService.put({ ...this.resourceForm } as any);
        Message.success(translation('operationRes.editSuccess'));
        this.$emit('edit-success', this.resourceForm);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }

  public get currentIsMenu(): boolean {
    if (this.resourceForm.scope !== PermissionScopeEnum.menu) {
      return false;
    }
    this.resourceForm.code = '';
    return true;
  }

  public get currentIsButton(): boolean {
    if (this.resourceForm.scope !== PermissionScopeEnum.button) {
      return false;
    }
    this.resourceForm.url = '';
    this.resourceForm.icon = '';
    return true;
  }

  private async getPermissionMenuResource(): Promise<void> {
    try {
      const resources = await platformPermissionService.getList({});
      this.treeOption = this.handleCascaderOption(resources);
    } catch (error) {
      messageError(error);
    }
  }

  private handleCascaderOption(permissionResource: Array<PermissionResource>): Array<CascaderOption> {
    const menuResources = permissionResource.filter(x => x.scope === PermissionScopeEnum.menu);
    return menuResources.map(x => {
      const option: CascaderOption = {
        label: x.name,
        value: x.code
      };
      if (x.children && x.children.length > 0) {
        option.children = this.handleCascaderOption(x.children);
      }
      return option;
    });
  }

  /**
   * 从用于选择的平台资源级联选择选项中，过滤掉要修改的资源本身
   * @param treeOption 平台资源树状数据
   * @returns
   */
  private filterSelfResource(treeOption: Array<CascaderOption>): Array<CascaderOption> {
    return treeOption.filter(x => {
      if (x.children && x.children.length > 0) {
        x.children = this.filterSelfResource(x.children);
      }
      return x.value !== this.resourceItem?.code;
    });
  }
  @Watch('resourceForm.parentId', { deep: true })
  private setParentId(value: string | null): void {
    if (!value) {
      this.resourceForm.parentId = '0';
    }
  }
}
